import * as React from "react";
import {concatenate} from "../../services/string";

interface Props {
  name: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  value: any
  colour?: string
}

function StatBox(props: Props) {
  return (
    <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
      <dt>
        <div className={concatenate("absolute rounded-md p-3", props.colour ?? 'bg-indigo-800')}>
          <props.icon className="h-6 w-6 text-white" aria-hidden="true"/>
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">{props.name}</p>
      </dt>
      <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{props.value.toLocaleString()}</p>
      </dd>
    </div>
  );
}

export default StatBox;