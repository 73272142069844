import React, {ChangeEvent} from 'react';
import CountryOptions from "./CountryOptions";

// Types ----

type CountrySelectProps = {
  id?: string
  value?: string | null
  onChange: (country: string) => void
  required?: boolean
}

// Component ----

function CountrySelect({id, value, onChange, required}: CountrySelectProps) {

  function selectCountry(event: ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value)
  }

  return (
    <select
      id={id}
      value={value || 'United Kingdom'}
      onChange={selectCountry}
      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      required={!!required}
    >
      <CountryOptions/>
    </select>
  );
}

export default CountrySelect;