import React from "react";
import Spinner from "../Spinner/Spinner";
import Badge from "../Badge/Badge";

interface Props {
    title: string
    badge?: string
    actions?: React.ReactNode
    children?: React.ReactNode
}

function View({title, badge, actions, children}: Props) {
    return (
        <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                    {badge !== undefined?
                        <div className="ml-3">
                            <Badge>{badge}</Badge>
                        </div>
                        : null}
                </div>

                <div className="flex space-x-2">{actions}</div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-2">
                {children || (
                    <div className="h-screen w-full flex justify-center items-center">
                        <Spinner size={Spinner.sizes.X_LARGE} color={Spinner.colors.DARK}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default View;