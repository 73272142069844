import {NavLink} from "react-router-dom";
import React from "react";

interface Props {
    name: string
    href: string
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

function NavItem(props: Props) {
    return (
        <NavLink
            to={props.href}
            className={({isActive}) =>
                isActive ?
                    'bg-gray-900 text-white group flex items-center px-2 py-2 text-base font-medium rounded-md' :
                    'text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-base font-medium rounded-md'
            }
        >
            {({isActive}) => (
                <>
                    <props.icon
                        className={isActive ? 'text-gray-300 mr-4 flex-shrink-0 h-6 w-6' : 'text-gray-400 group-hover:text-gray-300 mr-4 flex-shrink-0 h-6 w-6'}
                        aria-hidden="true"
                    />
                    {props.name}
                </>
            )}

        </NavLink>
    );
}

export default NavItem;