import React from 'react';
import {useNotification} from "../../context/NotificationContext";
import Notification from "../Notification/Notification";

function NotificationCentre() {
    const {notifications} = useNotification()

    return (
        <div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        type={notification.type}
                        title={notification.title}
                        duration={notification.duration}
                    >
                        {notification.body}
                    </Notification>
                ))}
            </div>
        </div>
    );
}

export default NotificationCentre;