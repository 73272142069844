import React, {EventHandler} from 'react';
import Spinner from "../Spinner/Spinner";

const sizes = {
    xs: 'px-2.5 py-1.5 text-xs',
    sm: 'px-3 py-2 text-sm',
    md: 'px-4 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
    xl: 'px-6 py-3 text-base',
};

const colors = {
    gray: 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white border-transparent',
    purple: 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 text-white border-transparent',
    indigo: 'bg-indigo-800 hover:bg-indigo-900 focus:ring-indigo-700 text-white border-transparent',
    blue: 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white border-transparent',
    green: 'bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white border-transparent',
    yellow: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white border-transparent',
    red: 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white border-transparent',
    white: 'bg-white hover:bg-gray-50 text-gray-700 border-gray-300'
}

interface Props {
    type?: "button" | "reset" | "submit"
    size?: keyof typeof sizes
    color?: keyof typeof colors
    disabled?: boolean
    className?: string
    onClick?: EventHandler<any>
    showSpinner?: boolean
    children: React.ReactNode
}

function Button({type, size, color, disabled, className, onClick, showSpinner, children}: Props) {
    const classes = disabled? `bg-gray-100 text-gray-400 border-gray-300 ${className}` : `${colors[color || 'white']} ${className}`;

    return (
        <button
            type={type || 'button'} disabled={!!disabled || showSpinner} onClick={onClick}
            className={`inline-flex items-center border font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${sizes[size || 'sm']} ${classes}`}
        >
            {showSpinner?
                <div className="mr-3">
                    <Spinner color={Spinner.colors.LIGHT} size={Spinner.sizes.X_SMALL}/>
                </div>
            : null}

            {children}
        </button>
    );
}

export default Button;