import React, {useEffect} from "react";
import api from "../services/api";
import {Navigate, useLocation} from "react-router-dom";
import * as Sentry from "@sentry/react";

interface AuthResult {
    loggedIn: boolean
    message?: string
}

interface User {
    id: number
    name: string
    profile_photo_url: string
}

interface AuthContextType {
    user: User | null;
    login: (email: string, password: string, remember: boolean) => Promise<AuthResult>;
    logout: () => Promise<boolean>;
}

function rememberUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user))
}

function rememberedUser(): User | null {
    return JSON.parse(localStorage.getItem('user') || 'null')
}

function forgetUser(): void {
    localStorage.removeItem('user')
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({children}: { children: React.ReactNode }) {
    const [user, setUser] = React.useState<User | null>(rememberedUser());

    useEffect(() => {
        if(user){
            Sentry.setUser({...user, id: user.id.toString()})
        } else {
            Sentry.configureScope(scope => scope.setUser(null));
        }
    }, [user]);


    let login = async (email: string, password: string, remember: boolean) => {
        const result = await api.auth.login(email, password, remember)

        if (result.loggedIn) {
            const currentUser = await api.auth.currentUser()

            if (currentUser) {
                setUser(currentUser)

                if (remember) {
                    rememberUser(currentUser)
                }
            } else {
                setUser(null)
                forgetUser()
            }
        }

        return result
    };

    let logout = async () => {
        const loggedOut = await api.auth.logout()

        setUser(null)
        forgetUser()

        return loggedOut
    };

    let value = {user, login, logout};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they log in, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}