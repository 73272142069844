import React from 'react';

const colors = {
    gray: 'bg-gray-600 text-gray-50',
    blue: 'bg-blue-600 text-gray-50',
    green: 'bg-green-600 text-gray-50',
    yellow: 'bg-yellow-600 text-gray-50',
    red: 'bg-red-600 text-gray-50',
}

const sizes = {
    small: 'text-xs',
    large: 'text-sm'
}

function Badge({size, color, children}: { size?: keyof typeof sizes, color?: keyof typeof colors, children: React.ReactNode }) {
    return (
        <span className={`inline-flex items-center px-2 py-0.5 font-medium rounded-full ${sizes[size || 'small']} ${colors[color || 'gray']}`}>
            {children}
        </span>
    );
}

export default Badge;