import React, {useState} from 'react';
import {useNotification} from "../../../context/NotificationContext";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import api from "../../../services/api";
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import {Field, Form, Formik, FormikHelpers} from "formik";

interface Values {
    id: string | number
    name: string
    email: string
    password?: string
    password_confirmation?: string
}

function UserUpdate() {
    const {id} = useParams()
    const {data, mutate, error} = useSWR(`/users/${id}`)
    const {notify} = useNotification()
    const navigate = useNavigate()

    const [changingPassword, setChangingPassword] = useState<boolean>(false);

    // Submit the form data
    function handleSubmit(values: Values, {setSubmitting} : FormikHelpers<Values>) {
        api.users.update(values)
            .then(() => notify("success" as const, "User updated", `${values.name}'s information has been updated`))
            .then(() => mutate())
            .then(() => navigate('/users'))
            .catch(() => setSubmitting(false))
    }

    // Show loading state
    if(!data && !error){
        return (
            <View title="Update User"/>
        )
    }

    // Extract values from fetched data
    const values = {
        id: id || 0,
        name: data.name,
        email: data.email
    }

    // Show user form
    return (
        <View title="Update User">
            <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <Formik initialValues={values} onSubmit={handleSubmit}>
                        {formik => (
                            <Form className="space-y-8 divide-y divide-gray-200" >
                                <div className="space-y-8 divide-y divide-gray-200">
                                    <div>
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Account</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be used to set up the user's account.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-2">
                                                <label htmlFor="name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Name
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="name"
                                                        autoFocus
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="5"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="email"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Email
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        autoComplete="email"
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="4"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            {!changingPassword?
                                                <div className="sm:col-span-6">
                                                    <Button color="white" onClick={() => setChangingPassword(true)}>
                                                        Change Password
                                                    </Button>
                                                </div>
                                            :
                                                <>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="password"
                                                               className="block text-sm font-medium text-gray-700">
                                                            Password
                                                        </label>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Field
                                                                type="password"
                                                                name="password"
                                                                id="password"
                                                                autoComplete="password"
                                                                autoFocus
                                                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                                min="6"
                                                                max="255"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="password_confirmation"
                                                               className="block text-sm font-medium text-gray-700">
                                                            Confirm Password
                                                        </label>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Field
                                                                type="password"
                                                                name="password_confirmation"
                                                                id="password_confirmation"
                                                                className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                                min="6"
                                                                max="255"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="sm:col-span-1 flex items-end">
                                                        <Button color="white" onClick={() => setChangingPassword(false)}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-end">
                                        <Button color="white" onClick={() => navigate('/users')}>Cancel</Button>
                                        <Button type="submit" color="indigo" className="ml-3" disabled={!formik.isValid} showSpinner={formik.isSubmitting}>Update</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </View>
    );
}

export default UserUpdate;