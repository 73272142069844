import camelcase from "camelcase";

export function concatenate(...classes: (false | null | undefined | string)[]) {
    return classes.filter(Boolean).join(" ");
}

export function truncate(string: string | null | undefined, length: number = 100) : string | undefined {
    if(!string){
        return undefined
    }

    return string.length > length ? `${string.substring(0, length)}...` : string;
}

export function toCamelCase(string: string): string {
    return camelcase(string)
}

export function toPascalCase(string: string): string {
    return camelcase(string, {pascalCase: true})
}

export function toTitleCase(string: string): string {
    return string.replace('_', ' ').replace(/\w\S*/g, word => {
        return word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase();
    });
}

export function isUrl(string: string): boolean {
    let url;

    try{
        url = new URL(string)
    } catch (error) {
        return false
    }

    return ['http:', 'https:'].includes(url.protocol)
}