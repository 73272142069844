import { LogoutIcon } from "@heroicons/react/outline";
import React, {useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import Spinner from "../Spinner/Spinner";

function CurrentUser() {
    const navigate = useNavigate()
    const auth = useAuth()

    const [loggingOut, setLoggingOut] = useState<boolean>(false);

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()

        setLoggingOut(true)

        auth.logout().then(loggedOut => {
            if(loggedOut){
                navigate('/')
            } else {
                setLoggingOut(false)
            }
        })
    }

    return (
        <div className="flex-shrink-0 flex bg-gray-700 p-4">
            <div className="flex-shrink-0 w-full group block flex justify-between">
                <div className="flex items-center">
                    <div>
                        <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={auth.user?.profile_photo_url}
                            alt={auth.user?.name || "Profile picture of current user"}
                        />
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-white">{auth.user?.name}</p>
                        {/*<p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">View profile</p>*/}
                    </div>
                </div>
                <button onClick={handleClick} disabled={loggingOut}>
                    {loggingOut? <Spinner color={Spinner.colors.LIGHT} size={Spinner.sizes.SMALL}/> : <LogoutIcon className="flex-shrink-0 h-5 w-5 text-white"/>}
                </button>
            </div>
        </div>
    );
}

export default CurrentUser;