import React, {PropsWithChildren, ReactNode} from 'react';

// Component ----

function DescriptionList({children}: PropsWithChildren<ReactNode>) {
  return (
    <div className="border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        {children}
      </dl>
    </div>
  );
}

// Export ----

export default DescriptionList;