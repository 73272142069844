import React, {ChangeEvent, useMemo} from 'react';
import {getTimeZones, TimeZone} from "@vvo/tzdb";
import TimeZoneOptions from "./TimeZoneOptions";

// Types ----

type TimeZoneSelectProps = {
  id?: string
  value?: string | null,
  onChange: (timeZone: string) => void
  required?: boolean
}

// Helpers ----

const allTimeZones = getTimeZones({includeUtc: true});
const defaultTimeZone = allTimeZones.find(timeZone => timeZone.name === 'Etc/UTC') as TimeZone;

function findTimeZone(name?: string | null): TimeZone {
  return allTimeZones.find(timeZone => !!name && (timeZone.name === name || timeZone.group.includes(name))) ?? defaultTimeZone;
}

// Component ----

function TimeZoneSelect({id, value, onChange, required}: TimeZoneSelectProps) {
  const timeZoneName = useMemo<string>(() => findTimeZone(value).name, [value]);

  function selectTimeZone(event: ChangeEvent<HTMLSelectElement>) {
    let selectedValue = event.target.value;

    if (selectedValue.startsWith('Etc/')) {
      selectedValue = selectedValue.substring(4);
    }

    onChange(selectedValue)
  }

  return (
    <select
      id={id}
      value={timeZoneName}
      onChange={selectTimeZone}
      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      required={!!required}
    >
      <TimeZoneOptions/>
    </select>
  );
}

export default TimeZoneSelect;