import React, {ChangeEvent} from 'react';
import LocaleOptions from "./LocaleOptions";

// Types ----

type LocaleSelectProps = {
  id?: string
  value?: string | null
  onChange: (locale: string) => void
  required?: boolean
}

// Component ----

function LocaleSelect({id, value, onChange, required}: LocaleSelectProps) {

  function selectLocale(event: ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value)
  }

  return (
    <select
      id={id}
      value={value || 'en'}
      onChange={selectLocale}
      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      required={!!required}
    >
      <LocaleOptions/>
    </select>
  );
}

export default LocaleSelect;