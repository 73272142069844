import React from 'react';
import {ExclamationIcon} from "@heroicons/react/outline";

const alertColors = {
    indigo: 'bg-indigo-50 border-indigo-400',
    blue: 'bg-blue-50 border-blue-400',
    green: 'bg-green-50 border-green-400',
    yellow: 'bg-yellow-50 border-yellow-400',
    red: 'bg-red-50 border-red-400',
};
const iconColors = {
    indigo: 'text-indigo-400',
    blue: 'text-blue-400',
    green: 'text-green-400',
    yellow: 'text-yellow-400',
    red: 'text-red-400'
};
const messageColors = {
    indigo: 'text-indigo-700',
    blue: 'text-blue-700',
    green: 'text-green-700',
    yellow: 'text-yellow-700',
    red: 'text-red-700'
};

interface Props {
    color: keyof typeof alertColors
    icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
    children: React.ReactNode
}

function Alert({color, icon, children}: Props) {
    const Icon = icon || ExclamationIcon

    return (
        <div className={`mb-4 border-l-4 p-4 ${alertColors[color]}`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <Icon className={`h-5 w-5 ${iconColors[color]}`} aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <p className={`text-sm ${messageColors[color]}`}>
                        {children}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Alert;