import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../../context/NotificationContext";
import useSWR from "swr";
import api from "../../../services/api";
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import {Field, Form, Formik, FormikHelpers} from "formik";
import LocaleSelect from "../../../components/LocaleSelect";
import CurrencySelect from "../../../components/CurrencySelect";
import CountrySelect from "../../../components/CountrySelect";

// Types ----

interface OperatorUpdateData {
  id: number | string
  name: string
  domain: string
  email: string,
  phone: string,
  address: {
    line1: string
    line2?: string
    line3?: string
    city: string
    postcode: string
    country: string
  },
  localisation: {
    locale: string
    currency: string
  }
}

// Helpers ----

function OperatorUpdate() {
  const {id} = useParams()
  const {data, mutate, error} = useSWR(`/operators/${id}`)
  const {notify} = useNotification()
  const navigate = useNavigate()

  // Submit the form data
  function handleSubmit(values: OperatorUpdateData, {setSubmitting}: FormikHelpers<OperatorUpdateData>) {
    api.operators.update(values)
      .then(() => notify("success" as const, "Operator updated", `${values.name}'s information has been updated`))
      .then(() => mutate())
      .then(() => navigate('/operators'))
      .catch(() => setSubmitting(false))
  }

  // Show loading state
  if (!data && !error) {
    return (
      <View title="Update Operator"/>
    )
  }

  // Extract values from fetched data
  const values: OperatorUpdateData = {
    id: parseInt(id as string),
    name: data.name || '',
    domain: data.domain || '',
    email: data.email || '',
    phone: data.phone || '',
    address: {
      line1: data.address?.line1 || '',
      line2: data.address?.line2 || '',
      line3: data.address?.line3 || '',
      city: data.address?.city || '',
      postcode: data.address?.postcode || '',
      country: data.address?.country || '',
    },
    localisation: {
      currency: data.localisation.currency || '',
      locale: data.localisation.locale || ''
    }
  }

  // Show operator form
  return (
    <View title="Update Operator">
      <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <Formik initialValues={values} onSubmit={handleSubmit}>
            {formik => (
              <Form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Identity</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        This information is used to identify the operator.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Name *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="operator-name"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 xl:col-span-1">
                        <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
                          Domain *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="domain"
                            id="domain"
                            autoComplete="operator-domain"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300"
                            min="3"
                            max="100"
                            required
                          />
                          <span
                            className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">.n-gage.io</span>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Details</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        An email address and street address must be provided.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email Address *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel-national"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="15"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 sm:col-start-1 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line1" className="block text-sm font-medium text-gray-700">
                          Address (Line 1) *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line1"
                            id="address-line1"
                            autoComplete="address-line1"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line2" className="block text-sm font-medium text-gray-700">
                          Address (Line 2)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line2"
                            id="address-line2"
                            autoComplete="address-line2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line3" className="block text-sm font-medium text-gray-700">
                          Address (Line 3)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line3"
                            id="address-line3"
                            autoComplete="address-line3"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-2 lg:col-start-1">
                        <label htmlFor="address-city" className="block text-sm font-medium text-gray-700">
                          Town/City *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.city"
                            id="address-city"
                            autoComplete="address-level2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-span-2 lg:col-span-1">
                        <label htmlFor="address-postcode" className="block text-sm font-medium text-gray-700">
                          Post Code *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.postcode"
                            id="address-postcode"
                            autoComplete="postal-code"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="10"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2 sm:col-start-1 lg:col-start-1">
                        <label htmlFor="address-country" className="block text-sm font-medium text-gray-700">
                          Country *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CountrySelect
                            id="address-country"
                            value={formik.values.address.country}
                            onChange={country => formik.setFieldValue('address.country', country)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Localisation</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Apply localisation settings for the operator's country or region
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-2 lg:col-span-2 col-start-1 lg:col-start-1">
                        <label htmlFor="locale" className="block text-sm font-medium text-gray-700">
                          Locale *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <LocaleSelect
                            id="locale"
                            value={formik.values.localisation.locale}
                            onChange={locale => formik.setFieldValue('localisation.locale', locale)}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 lg:col-span-2">
                        <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                          Currency *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CurrencySelect
                            id="currency"
                            value={formik.values.localisation.currency}
                            onChange={currency => formik.setFieldValue('localisation.currency', currency)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button color="white" onClick={() => navigate('/operators')}>
                      Cancel
                    </Button>

                    <Button type="submit" color="indigo" className="ml-3" disabled={!formik.isValid} showSpinner={formik.isSubmitting}>
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </View>
  );
}

export default OperatorUpdate;