import React, {useState} from 'react';
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import api from "../../../services/api";
import {useNotification} from "../../../context/NotificationContext";
import {useNavigate} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers} from "formik";
import ConfirmModal from "../../../components/ConfirmModal";
import Alert from "../../../components/Alert/Alert";
import {KeyIcon, MailIcon} from "@heroicons/react/outline";
import TimeZoneSelect from "../../../components/TimeZoneSelect";
import CountrySelect from "../../../components/CountrySelect";
import LocaleSelect from "../../../components/LocaleSelect";
import CurrencySelect from "../../../components/CurrencySelect";
import SubdomainField from "../../../components/SubdomainField";

// Types ----

type ProvisionedOperator = {
  id: number
  name: string
  domain: string
  is_active: boolean
  admin_user: {
    email: string
    password: string
  }
}

interface OperatorData {
  name: string
  domain: string
  email: string,
  phone?: string,
  address: {
    line1: string
    line2?: string
    line3?: string
    city: string
    postcode: string
    country: string
  },
  localisation: {
    locale: string
    currency: string
    timezone: string
  }
}

// Helpers ----

const domain = process.env.REACT_APP_DOMAIN || 'n-gage.io'

const initialValues: OperatorData = {
  name: '',
  domain: '',
  email: '',
  phone: '',
  address: {
    line1: '',
    line2: '',
    line3: '',
    city: '',
    postcode: '',
    country: 'United Kingdom',
  },
  localisation: {
    locale: 'en',
    currency: 'GBP',
    timezone: 'UTC',
  }
}

// Component ----

function OperatorCreate() {
  const [operator, setOperator] = useState<ProvisionedOperator | null>(null);

  const {notify} = useNotification();
  const navigate = useNavigate();

  // Submit the form data
  function handleSubmit(values: OperatorData, {setSubmitting}: FormikHelpers<OperatorData>) {
    notify("info" as const, "Provisioning operator", `Provisioning a system for ${values.name}...`)

    api.operators.create(values)
      .then(setOperator)
      .catch(() => notify("error" as const, "Operator Not Provisioned", `${values.name}'s system could not be provisioned; contact support.`))
      .finally(() => setSubmitting(false))
  }

  function handleDismissCredentials() {
    notify("success" as const, "Operator provisioned", `${operator?.name}'s system has been provisioned`)
    setOperator(null)
    navigate('/operators')
  }

  return (
    <View title="New Operator">

      {/* Operator credentials modal */}
      <ConfirmModal
        show={!!operator}
        title="Operator Credentials"
        onApprove={handleDismissCredentials}
        canCancel={false}
      >
        <p>
          {operator ? `${operator.name}'s` : 'The'} system has been provisioned and is ready for use.
        </p>

        <p className="mt-2">
          Administrator credentials have been emailed to the operator and also to you. For convenience,
          they're temporarily shown below.
        </p>

        <p className="mt-2">
          Once dismissed, the password will not be shown again.
        </p>

        {/* TODO: Add a clipboard button to quickly copy these to the clipboard */}
        <div className="mt-4">
          <Alert color="blue" icon={MailIcon}>
            <strong>Email</strong> {operator?.admin_user.email || 'Unknown'}
          </Alert>

          <Alert color="blue" icon={KeyIcon}>
            <strong>Password</strong> {operator?.admin_user.password || 'Unknown'}
          </Alert>
        </div>
      </ConfirmModal>

      {/* Page/form contents */}
      <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {formik => (
              <Form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Identity</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        This information will be used to identify the operator and set up their
                        system.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700">
                          Name *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="name"
                            autoComplete="operator-name"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label htmlFor="domain" className="block text-sm font-medium text-gray-700">
                          Domain *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <SubdomainField
                            type="text"
                            name="domain"
                            suggestionField="name"
                            autoComplete="operator-domain"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300"
                            min="3"
                            max="100"
                            required
                          />
                          <span
                            className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                            .{domain}
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Contact Details</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        An email address and street address must be provided.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email Address *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="email"
                            name="email"
                            autoComplete="email"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                          Phone Number
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="phone"
                            autoComplete="tel-national"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min="5"
                            max="15"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 sm:col-start-1 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line1" className="block text-sm font-medium text-gray-700">
                          Address (Line 1) *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line1"
                            autoComplete="address-line1"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line2" className="block text-sm font-medium text-gray-700">
                          Address (Line 2)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line2"
                            autoComplete="address-line2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-4 lg:col-start-1">
                        <label htmlFor="address-line3" className="block text-sm font-medium text-gray-700">
                          Address (Line 3)
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.line3"
                            autoComplete="address-line3"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6 lg:col-span-2 lg:col-start-1">
                        <label htmlFor="address-city" className="block text-sm font-medium text-gray-700">
                          Town/City *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.city"
                            autoComplete="address-level2"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="255"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 lg:col-span-1">
                        <label htmlFor="address-postcode" className="block text-sm font-medium text-gray-700">
                          Post Code *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="address.postcode"
                            autoComplete="postal-code"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            max="10"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 lg:col-span-2 sm:col-start-1 lg:col-start-1">
                        <label htmlFor="address-country" className="block text-sm font-medium text-gray-700">
                          Country *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CountrySelect
                            value={formik.values.address.country}
                            onChange={country => formik.setFieldValue('address.country', country)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Localisation</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Apply localisation settings for the operator's country or region
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-3 sm:col-start-1 lg:col-start-1">
                        <label htmlFor="time-zone" className="block text-sm font-medium text-gray-700">
                          Time Zone *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <TimeZoneSelect
                            value={formik.values.localisation.timezone}
                            onChange={timeZone => formik.setFieldValue('localisation.timezone', timeZone)}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 lg:col-span-2 col-start-1 lg:col-start-1">
                        <label htmlFor="locale" className="block text-sm font-medium text-gray-700">
                          Locale *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <LocaleSelect
                            value={formik.values.localisation.locale}
                            onChange={locale => formik.setFieldValue('localisation.locale', locale)}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2 lg:col-span-2">
                        <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                          Currency *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <CurrencySelect
                            value={formik.values.localisation.currency}
                            onChange={currency => formik.setFieldValue('localisation.currency', currency)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button
                      color="white"
                      onClick={() => navigate('/operators')}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="indigo"
                      className="ml-3"
                      disabled={!formik.isValid || formik.isSubmitting}
                      showSpinner={formik.isSubmitting}
                    >
                      Provision
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </View>
  );
}

export default OperatorCreate;