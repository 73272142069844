import React from 'react';
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import api from "../../../services/api";
import {useNotification} from "../../../context/NotificationContext";
import {useNavigate} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers} from "formik";

interface Values {
    name: string
    email: string
    password: string
    password_confirmation: string
}

function UserCreate() {
    const {notify} = useNotification()
    const navigate = useNavigate()

    // Submit the form data
    function handleSubmit(values: Values, {setSubmitting}: FormikHelpers<Values>) {
        api.users.create(values)
            .then(() => notify("success" as const, "User registered", `${values.name} has been registered as an Administration user`))
            .then(() => navigate('/users'))
            .catch(() => setSubmitting(false))
    }

    const values = {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    }

    return (
        <View title="New User">
            <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <Formik initialValues={values} onSubmit={handleSubmit}>
                        {formik => (
                            <Form className="space-y-8 divide-y divide-gray-200">
                                <div className="space-y-8 divide-y divide-gray-200">
                                    <div>
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Account</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be used to set up the user's account.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-2">
                                                <label htmlFor="name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Name
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="name"
                                                        autoFocus
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="5"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="email"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Email
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        autoComplete="email"
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="4"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <label htmlFor="password"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Password
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        autoComplete="password"
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="6"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <label htmlFor="password_confirmation"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Confirm Password
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Field
                                                        type="password"
                                                        name="password_confirmation"
                                                        id="password_confirmation"
                                                        className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                        min="6"
                                                        max="255"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-end">
                                        <Button color="white" onClick={() => navigate('/users')}>Cancel</Button>
                                        <Button type="submit" color="indigo" className="ml-3" disabled={!formik.isValid} showSpinner={formik.isSubmitting}>Register</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </View>
    );
}

export default UserCreate;