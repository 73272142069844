import React, {createContext, useContext, useState} from "react";

type NotificationType = "info" | "success" | "warning" | "error"

interface Notification {
    id: number
    type: NotificationType
    title: string
    body: string
    duration: number
}

interface NotificationContext {
    notifications: Array<Notification>
    notify: (type: NotificationType, title: string, body: string, duration?: number) => void
}

let Context = createContext<NotificationContext>(null!)

export function useNotification(): NotificationContext {
    return useContext(Context)
}

export function NotificationProvider({children}: { children: React.ReactNode }) {
    const [notifications, setNotifications] = useState<Array<Notification>>([]);

    function notify(type: NotificationType, title: string, body: string, duration: number = 3000): void {
        const id = Math.round(Math.random() * 10000)

        setNotifications([...notifications, {id, duration, type, title, body}])
    }

    let value = {notifications, notify}

    return <Context.Provider value={value}>{children}</Context.Provider>
}