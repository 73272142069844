import {Dialog, Transition} from '@headlessui/react';
import React, {Fragment, useState} from 'react';
import {CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon} from "@heroicons/react/solid";
import {concatenate} from "../../services/string";
import Spinner from "../Spinner";

enum Type { INFO, SUCCESS, WARNING, DANGER, ERROR }

type Props = {
    type: Type
    title: string
    body: string
    value?: string | null
    show: boolean
    approveLabel: string
    onApprove: () => any
    cancelLabel: string
    onCancel: () => any
    canCancel: boolean
    children?: React.ReactNode
}

const icons: Record<Type, JSX.Element> = {
    [Type.INFO]: <InformationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true"/>,
    [Type.SUCCESS]: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>,
    [Type.WARNING]: <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true"/>,
    [Type.DANGER]: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true"/>,
    [Type.ERROR]: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true"/>,
}

const iconHaloColors: Record<Type, string> = {
    [Type.INFO]: 'bg-indigo-100',
    [Type.SUCCESS]: 'bg-indigo-100',
    [Type.WARNING]: 'bg-indigo-100',
    [Type.DANGER]: 'bg-red-100',
    [Type.ERROR]: 'bg-red-100',
}

const buttonClasses: Record<Type, string> = {
    [Type.INFO]: 'bg-indigo-800 hover:bg-indigo-900 focus:ring-indigo-800',
    [Type.SUCCESS]: 'bg-indigo-800 hover:bg-indigo-900 focus:ring-indigo-800',
    [Type.WARNING]: 'bg-indigo-800 hover:bg-indigo-900 focus:ring-indigo-800',
    [Type.DANGER]: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
    [Type.ERROR]: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
}

function ConfirmModal({type, title, body, value, show, approveLabel, onApprove, cancelLabel, onCancel, canCancel, children}: Props) {
    const [handling, setHandling] = useState<boolean>(false);
    const [valueConfirmation, setValueConfirmation] = useState<string>('');
    const canApprove = (!value || (value && value === valueConfirmation))

    function handleCancel() {
        if(canCancel){
            setHandling(false)
            return onCancel()
        }
    }

    function handleApprove() {
        if(canApprove){
            setHandling(true)
            return onApprove()
        }
    }

    return (
        <Transition show={show} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCancel}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className={concatenate(iconHaloColors[type], 'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10')}>
                                    {icons[type]}
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-700">
                                            {children || body}
                                        </p>

                                        {value?
                                            <div className="mt-2 text-gray-700">
                                                <p className="text-sm">To confirm, enter "<span className="font-semibold">{value}</span>" below.</p>

                                                <label className="sr-only" htmlFor="value-confirmation">Value Confirmation</label>
                                                <input
                                                    type="text"
                                                    id="value-confirmation"
                                                    value={valueConfirmation}
                                                    onChange={event => setValueConfirmation(event.currentTarget.value)}
                                                    className="mt-2 flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className={concatenate(canApprove? buttonClasses[type] : 'bg-gray-100 text-gray-500', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm')}
                                    onClick={handleApprove}
                                    disabled={!canApprove || handling}
                                >
                                    {handling? <div className="-ml-1 mr-2"><Spinner color={Spinner.colors.LIGHT} size={Spinner.sizes.X_SMALL}/></div> : null}
                                    {approveLabel}
                                </button>

                                {canCancel &&
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={handleCancel}
                                        disabled={handling}
                                    >
                                        {cancelLabel}
                                    </button>
                                }
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

ConfirmModal.types = Type

ConfirmModal.defaultProps = {
    type: Type.INFO,
    show: true,
    body: '',
    approveLabel: 'OK',
    onApprove: () => null,
    cancelLabel: 'Cancel',
    onCancel: () => null,
    canCancel: true,
}

export default ConfirmModal;