import {AuthProvider, RequireAuth} from "../../context/AuthContext";
import {NotificationProvider} from "../../context/NotificationContext";
import {Routes, Route} from "react-router-dom";
import Layout from "../Layout/Layout";
import Dashboard from "../../views/Dashboard/Dashboard";
import UserList from "../../views/User/UserList/UserList";
import UserCreate from "../../views/User/UserCreate/UserCreate";
import UserUpdate from "../../views/User/UserUpdate/UserUpdate";
import OperatorList from "../../views/Operator/OperatorList/OperatorList";
import OperatorCreate from "../../views/Operator/OperatorCreate/OperatorCreate";
import OperatorUpdate from "../../views/Operator/OperatorUpdate/OperatorUpdate";
import Login from "../../views/Login/Login";
import Config from "../Config/Config";
import OperatorView from "../../views/Operator/OperatorView/OperatorView";
import OperatorStatsUpdate from "../../views/OperatorStats/OperatorStatsUpdate/OperatorStatsUpdate";

function App() {
  return (
    <AuthProvider>
      <Config>
        <NotificationProvider>
          <Routes>
            <Route element={<Layout/>}>
              <Route path="/" element={<RequireAuth><Dashboard/></RequireAuth>}/>

              <Route path="/operators/:id" element={<RequireAuth><OperatorView/></RequireAuth>}/>
              <Route path="/operators/:id/update" element={<RequireAuth><OperatorUpdate/></RequireAuth>}/>
              <Route path="/operators/:id/stats/update" element={<RequireAuth><OperatorStatsUpdate/></RequireAuth>}/>
              <Route path="/operators/create" element={<RequireAuth><OperatorCreate/></RequireAuth>}/>
              <Route path="/operators" element={<RequireAuth><OperatorList/></RequireAuth>}/>

              <Route path="/users/:id/update" element={<RequireAuth><UserUpdate/></RequireAuth>}/>
              <Route path="/users/create" element={<RequireAuth><UserCreate/></RequireAuth>}/>
              <Route path="/users" element={<RequireAuth><UserList/></RequireAuth>}/>
            </Route>

            <Route path="/login" element={<Login/>}/>
          </Routes>
        </NotificationProvider>
      </Config>
    </AuthProvider>
  );
}

export default App;
