import React, {MouseEventHandler} from 'react';
import {PlusIcon} from "@heroicons/react/solid";
import Button from "../Button/Button";

interface Props {
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
    title: string
    subtitle: string
    buttonText: string
    onClick: MouseEventHandler<HTMLButtonElement>
}

function EmptyState({icon, title, subtitle, buttonText, onClick}: Props) {
    const Icon = icon

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg mt-4">
            <div className="px-4 py-5 sm:p-6">
                <div className="text-center">
                    <Icon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
                    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                    <div className="mt-6">
                        <Button onClick={onClick} color="purple">
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmptyState;