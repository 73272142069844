import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {HomeIcon, MapIcon, MenuIcon, UsersIcon, XIcon} from '@heroicons/react/outline'
import CurrentUser from "../CurrentUser/CurrentUser";
import NavItem from "../NavItem/NavItem";
import { Outlet } from 'react-router-dom';
import logo from "../../assets/n-gage-io-logo-light.svg"
import NotificationCentre from "../../components/NotificationCentre/NotificationCentre";

const navItems = [
    {name: 'Dashboard', path: '/', icon: HomeIcon},
    {name: 'Operators', path: '/operators', icon: MapIcon},
    {name: 'Users', path: '/users', icon: UsersIcon},
];

function Layout() {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <div>
            {/* Sliding sidebar for mobile */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <div>
                                        <img className="h-8 w-auto" src={logo} alt="n-gage.io"/>
                                        <span className="text-gray-300 text-sm font-medium">Administration</span>
                                    </div>
                                </div>
                                <nav className="mt-5 px-2 space-y-1">
                                    {navItems.map((item) => (
                                        <NavItem name={item.name} href={item.path} icon={item.icon} key={item.name}/>
                                    ))}
                                </nav>
                            </div>

                            <CurrentUser/>

                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component */}
                <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
                    <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4">
                            <div>
                                <img className="h-8 w-auto" src={logo} alt="n-gage.io"/>
                                <span className="text-gray-300 text-sm font-medium">Administration</span>
                            </div>
                        </div>
                        <nav className="mt-5 flex-1 px-2 space-y-1">
                            {navItems.map((item) => (
                                <NavItem name={item.name} href={item.path} icon={item.icon} key={item.name}/>
                            ))}
                        </nav>
                    </div>

                    <CurrentUser/>

                </div>
            </div>
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>

                {/* Main content */}
                <main className="flex-1">
                    <Outlet/>
                </main>

                {/* Notifications */}
                <NotificationCentre/>
            </div>
        </div>
    );
}

export default Layout