import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../../context/NotificationContext";
import useSWR from "swr";
import api from "../../../services/api";
import View from "../../../components/View/View";
import Button from "../../../components/Button/Button";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {DateTime} from "luxon";

// Types ----

interface OperatorStatsUpdateData {
  operator_id: number | string
  date: string
  android_impressions: number
  android_downloads: number
  android_conversion_rate: number
  ios_impressions: number
  ios_downloads: number
  ios_conversion_rate: number
}

// Helpers ----

const thisWeek = DateTime.now().startOf('week').plus({hours: 12})
const weeks: Array<DateTime> = [];

while(weeks.length < 52) {
  weeks.push(thisWeek.minus({week: weeks.length}));
}

// Component ----

function OperatorStatsUpdate() {
  const {id} = useParams();
  const {data: operator, error} = useSWR(`/operators/${id}`);

  const {notify} = useNotification();
  const navigate = useNavigate();

  //Submit the form data
  function handleSubmit(values: OperatorStatsUpdateData, {setSubmitting}: FormikHelpers<OperatorStatsUpdateData>) {
    api.statChanges.create(values)
      .then(() => notify("success" as const, "Operator Stats updated", `${operator.name}'s app stats have been updated`))
      .then(() => navigate(`/operators/${id}`))
      .catch(() => setSubmitting(false))
  }

  // Show loading state
  if (!operator && !error) {
    return (
      <View title="Loading..."/>
    )
  }

  // Prepare form data
  const values: OperatorStatsUpdateData = {
    operator_id: Number(id),
    date: DateTime.now().toISODate(),
    android_impressions: 0,
    android_downloads: 0,
    android_conversion_rate: 0,
    ios_impressions: 0,
    ios_downloads: 0,
    ios_conversion_rate: 0,
  }

  // Show operator form
  return (
    <View title="Update Operator Stats">
      <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <Formik initialValues={values} onSubmit={handleSubmit}>
            {formik => (
              <Form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">

                  {/* Change Date */}
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Time Period</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Choose the week when these stat changes took place.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 lg:col-span-2">
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                          Week *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            as="select"
                            name="date"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            required
                          >
                            {weeks.map((week, index) => (
                              <option value={week.toUTC().toISO()}>
                                {week.toLocaleString({dateStyle: 'medium'})} to {week.endOf('week').toLocaleString({dateStyle: 'medium'})}
                                {index === 0 && ' (this week)'}
                                {index === 1 && ' (last week)'}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Android */}
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Android Stats</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Enter the Android stat changes for the week.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                      <div className="sm:col-span-4 lg:col-span-2">
                        <label htmlFor="android_impressions" className="block text-sm font-medium text-gray-700">
                          Impressions *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="android_impressions"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            step={1}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4 lg:col-span-2">
                        <label htmlFor="android_downloads" className="block text-sm font-medium text-gray-700">
                          Downloads *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="android_downloads"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            step={1}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4 lg:col-span-3">
                        <label htmlFor="android_conversion_rate" className="block text-sm font-medium text-gray-700">
                          Conversion Rate (%) *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="android_conversion_rate"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            max={100}
                            step={0.01}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                  {/* iOS */}
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">iOS Stats</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Enter the iOS stat changes for the week.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                      <div className="sm:col-span-4 lg:col-span-2">
                        <label htmlFor="ios_impressions" className="block text-sm font-medium text-gray-700">
                          Impressions *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="ios_impressions"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            step={1}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4 lg:col-span-2">
                        <label htmlFor="ios_downloads" className="block text-sm font-medium text-gray-700">
                          Downloads *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="ios_downloads"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            step={1}
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4 lg:col-span-3">
                        <label htmlFor="ios_conversion_rate" className="block text-sm font-medium text-gray-700">
                          Conversion Rate (%) *
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <Field
                            type="number"
                            name="ios_conversion_rate"
                            autoComplete="off"
                            className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            min={0}
                            max={100}
                            step={0.01}
                            required
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <Button color="white" onClick={() => navigate('/operators')}>
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      color="indigo"
                      className="ml-3"
                      disabled={!formik.isValid}
                      showSpinner={formik.isSubmitting}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </View>
  );
}

export default OperatorStatsUpdate;