import View from "../../components/View/View";
import useSWR from "swr";
import StatBox from "../../components/StatBox/StatBox";
import {
  BanIcon,
  ChartBarIcon,
  GlobeIcon,
  InformationCircleIcon,
  LockClosedIcon, LockOpenIcon,
  MapIcon, UserIcon,
  UsersIcon
} from "@heroicons/react/outline";
import * as React from "react";
import TimelineEntry from "../../components/TimelineEntry/TimelineEntry";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";

// Helpers ----

function statIcon(name: string) {
  const icons = new Map<string, (props: React.ComponentProps<'svg'>) => JSX.Element>([
    ['operators', MapIcon],
    ['domains', GlobeIcon],
    ['users', UsersIcon],
  ])

  return icons.get(name) || ChartBarIcon;
}

function activityIcon(name: string) {
  const icons = new Map<string, (props: React.ComponentProps<'svg'>) => JSX.Element>([
    ['operator', MapIcon],
    ['user', UserIcon],
    ['login-failed', BanIcon],
    ['login', LockOpenIcon],
    ['logout', LockClosedIcon],
  ])

  return icons.get(name) || InformationCircleIcon;
}

function sentenceCase(string: string) {
  return string.toLowerCase().replace(/(^\w)|\.\s+(\w)/gm, (letter: string) => letter.toUpperCase());
}

// Component ----

function Dashboard() {
  const {data, error} = useSWR('/insights')

  return (
    <View title="Dashboard">
      {(data && !error) ? <>
        {/* Headline stat boxes */}
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {Object.entries(data.counts).map((count) => (
            <StatBox key={count[0]} name={count[0]} value={count[1]} icon={statIcon(count[0])}/>
          ))}
        </dl>

        {/* Newest Operator and Admin User */}
        <div className="mt-4 sm:mt-8 grid sm:grid-cols-2 gap-4">
          <div>
            {/* Newest Operator */}
            {data.newest.operator ?
              <Link to={`/operators/${data.newest.operator.id}`}>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Newest Operator</h3>
                  <div
                    className="mt-4 relative rounded-lg bg-white px-6 py-5 shadow flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`https://ui-avatars.com/api/?name=${data.newest.operator.name}&color=f3f4f6&background=9333ea`}
                        alt={data.newest.operator.name}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">{data.newest.operator.name}</p>
                        <p className="text-sm text-gray-500 truncate">{data.newest.operator.domain}</p>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      Provisioned {DateTime.fromISO(data.newest.operator.created_at).toFormat('DD')}
                    </div>
                  </div>
                </div>
              </Link>
              : null}

            {/* Newest User */}
            {data.newest.user ?
              <div className="mt-4 sm:mt-8">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Newest Admin User</h3>
                <div
                  className="mt-4 relative rounded-lg bg-white px-6 py-5 shadow flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={data.newest.user.profile_photo_url}
                      alt={data.newest.user.name}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true"/>
                      <p className="text-sm font-medium text-gray-900">{data.newest.user.name}</p>
                      <p className="text-sm text-gray-500 truncate">Administrator</p>
                    </div>
                  </div>
                  <div className="text-sm text-gray-500">
                    Joined {DateTime.fromISO(data.newest.user.created_at).toFormat('DD')}
                  </div>
                </div>
              </div>
              : null}
          </div>

          {/* Latest activity */}
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Latest Activity</h3>
            <div className="mt-4 bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <div className="flow-root">
                  <ul className="-mb-8">
                    {data.activity.map((entry: any, index: number) => (
                      <TimelineEntry
                        key={entry.id}
                        preContent={!['login-failed', 'login', 'logout'].includes(entry.event) ? `${sentenceCase(entry.subject_type)} ${entry.subject?.name || entry.subject?.title || ''} ${entry.event} by` : null}
                        target={!['login-failed', 'login', 'logout'].includes(entry.event) ? entry.causer?.name || 'System' : entry.subject?.name}
                        postContent={['login-failed', 'login', 'logout'].includes(entry.event) ? (entry.event === 'login' ? 'logged in' : (entry.event === 'logout' ? 'logged out' : 'failed login')) : null}
                        dateTime={DateTime.fromISO(entry.created_at)}
                        colour="bg-indigo-800"
                        icon={['login-failed', 'login', 'logout'].includes(entry.event) ? activityIcon(entry.event) : activityIcon(entry.subject_type)}
                        isLast={index === data.activity.length - 1}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> : null}
    </View>
  );
}

export default Dashboard;