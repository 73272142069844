import React from 'react';
import View from "../../../components/View/View";
import {useNavigate, useParams} from "react-router-dom";
import useSWR from "swr";
import {DateTime} from "luxon";
import DescriptionList from "../../../components/DescriptionList/DescriptionList";
import DescriptionListItem from "../../../components/DescriptionList/DescriptionListItem";
import Badge from "../../../components/Badge/Badge";
import StatBox from "../../../components/StatBox/StatBox";
import {ChartPieIcon, DownloadIcon, EyeIcon} from "@heroicons/react/outline";
import Button from "../../../components/Button/Button";
import {PencilIcon,  PresentationChartLineIcon} from "@heroicons/react/solid";

// Helpers ----

const stats = [
  'app-impressions',
  'app-downloads',
  'app-conversion-rate',
  'android-app-impressions',
  'android-app-downloads',
  'android-app-conversion-rate',
  'ios-app-impressions',
  'ios-app-downloads',
  'ios-app-conversion-rate',
].join(',');

// Component ----

function OperatorView() {
  const navigate = useNavigate();

  const {id} = useParams();
  const {data: operator, error: operatorError} = useSWR(`/operators/${id}`);

  const start = DateTime.now().minus({day: 30}).startOf('minute').toUTC().toISO();
  const end = DateTime.now().toUTC().startOf('minute').toISO();

  const {data: timeSeries, error: timeSeriesError} = useSWR(
    `/time-series?operator=${id}&stats=${stats}&start_at=${start}&end_at=${end}&group=week`
  );

  // Show loading state
  if ((!operator || !timeSeries) && !operatorError && !timeSeriesError) {
    return <View title={"Loading..."}/>
  }

  // Prepare actions
  const actions = (<>
    <Button color="indigo" size="xs" onClick={() => navigate(`/operators/${id}/stats/update`)}>
      <PresentationChartLineIcon className="h-5 w-5 mr-2"/>
      Update Stats
    </Button>
    <Button color="indigo" size="xs" onClick={() => navigate(`/operators/${id}/update`)}>
      <PencilIcon className="h-5 w-5 mr-2"/>
      Update
    </Button>
  </>)

  // Show operator data
  return (
    <View title={operator.name} actions={actions}>

      {/* Info Card */}
      <div className="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Operator Information</h3>
        </div>

        <DescriptionList>
          <DescriptionListItem term={"Status"}>
            <Badge color="green">Active</Badge>
          </DescriptionListItem>

          <DescriptionListItem term={"Plan"}>
            {operator.settings.plan}
          </DescriptionListItem>

          <DescriptionListItem term={"Domain"}>
            {operator.domain}
          </DescriptionListItem>

          <DescriptionListItem term={"Email"}>
            {operator.email}
          </DescriptionListItem>

          <DescriptionListItem term={"Phone"}>
            {operator.phone}
          </DescriptionListItem>

          <DescriptionListItem term={"Address"}>
            {operator.address.line1}
          </DescriptionListItem>
        </DescriptionList>
      </div>

      {/* App Stats Card */}
      <div className="mt-8">
        <h3 className="text-base font-semibold leading-6 text-gray-900">App Stats</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">

          {/* All */}
          <StatBox
            name={"Impressions"}
            icon={EyeIcon}
            value={timeSeries.totals.app_impressions}
          />
          <StatBox
            name={"Downloads"}
            icon={DownloadIcon}
            value={timeSeries.totals.app_downloads}
          />
          <StatBox
            name={"Conversion Rate"}
            icon={ChartPieIcon}
            value={timeSeries.averages.app_conversion_rate + '%'}
          />

          {/* Android */}
          <StatBox
            name={"Android Impressions"}
            icon={EyeIcon}
            colour={'bg-green-600'}
            value={timeSeries.totals.android_app_impressions}
          />
          <StatBox
            name={"Android Downloads"}
            icon={DownloadIcon}
            colour={'bg-green-600'}
            value={timeSeries.totals.android_app_downloads}
          />
          <StatBox
            name={"Android Conversion Rate"}
            icon={ChartPieIcon}
            colour={'bg-green-600'}
            value={timeSeries.averages.android_app_conversion_rate + '%'}
          />

          {/* iOS */}
          <StatBox
            name={"iOS Impressions"}
            icon={EyeIcon}
            colour={'bg-gray-500'}
            value={timeSeries.totals.ios_app_impressions}
          />
          <StatBox
            name={"iOS Downloads"}
            icon={DownloadIcon}
            colour={'bg-gray-500'}
            value={timeSeries.totals.ios_app_downloads}
          />
          <StatBox
            name={"iOS Conversion Rate"}
            icon={ChartPieIcon}
            colour={'bg-gray-500'}
            value={timeSeries.averages.ios_app_conversion_rate + '%'}
          />
        </dl>
      </div>
    </View>
  );
}

export default OperatorView;