import React, {memo} from 'react';
import {getTimeZones} from "@vvo/tzdb";

// Helpers ----

const allTimeZones = getTimeZones({includeUtc: true});

// Component ----

function TimeZoneOptions() {
  return (
    <>
      {allTimeZones.map(timeZone => (
        <option value={timeZone.name} key={timeZone.name}>
          {timeZone.currentTimeFormat}
        </option>
      ))}
    </>
  );
}

export default memo(TimeZoneOptions);