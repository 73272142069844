import useSWR from "swr";
import View from "../../../components/View/View";
import {useState} from "react";
import EmptyState from "../../../components/EmptyState/EmptyState";
import {MapIcon, PencilIcon} from "@heroicons/react/outline";
import Badge from "../../../components/Badge/Badge";
import Button from "../../../components/Button/Button";
import {DateTime} from "luxon";
import {PlusIcon, TrashIcon} from "@heroicons/react/solid";
import {useNotification} from "../../../context/NotificationContext";
import {Link, useNavigate} from "react-router-dom";
import api from "../../../services/api";

interface User {
    id: number
    email: string
    name: string
    profile_photo_url: string
    created_at: string
}

function UserList() {
    const [page, setPage] = useState<number>(1);
    const {data, mutate, error} = useSWR(`/users?page=${page}`)
    const {notify} = useNotification()
    const navigate = useNavigate()

    // Pre-fetch the next page straight away
    useSWR(`/users?page=${page + 1}`)

    // TODO: Confirm this with a modal
    function deleteUser(id: number) {
        const user = data.data.find((user: User) => user.id === id)

        // noinspection JSIgnoredPromiseFromCall
        mutate((async (currentPage: any) => {
            return Object.assign({}, currentPage, {
                data: currentPage.data.filter((user: User) => user.id !== id),
                total: currentPage.total - 1
            })
        }), false)

        api.users.delete(id)
            .then(() => notify("success" as const, "User deleted", `${user.name}'s account has been removed`))
            .then(() => mutate())
    }

    const title = "Users"
    const actions = (<>
        <Button color="indigo" size="xs" onClick={() => navigate('/users/create')}>
            <PlusIcon className="h-5 w-5"/>
            New User
        </Button>
    </>)

    // Show loading state
    if(!data && !error){
        return (
            <View title={title} actions={actions}/>
        )
    }

    // Show empty state
    if(data && data.total === 0){
        return (
            <View title={title} badge={data.total}>
                <EmptyState icon={MapIcon} title="No users" subtitle="Get started by registering a new user." buttonText="New user" onClick={() => console.log("new user")}/>
            </View>
        )
    }

    // Show user list
    return (
        <View title={title} badge={data.total} actions={actions}>
            <div className="mt-4 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Role
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Registered
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.map((user: User, index: number) => (
                                    <tr key={user.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>

                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-10">
                                                    <img className="h-10 w-10 rounded-full" src={user.profile_photo_url} alt={user.name} />
                                                </div>
                                                <div className="ml-4">
                                                    <div className="text-sm font-medium text-gray-900">{user.name}</div>
                                                    <div className="text-sm text-gray-500">{user.email}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <Badge color="gray">Administrator</Badge>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {DateTime.fromISO(user.created_at).toFormat('DD')}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            {user.id !== 1?
                                                <>
                                                    <Link to={`/users/${user.id}/update`} className="p-1 inline-flex border border-transparent bg-indigo-700 hover:bg-indigo-900 shadow rounded-md text-white">
                                                        <PencilIcon className="h-4 w-4" />
                                                    </Link>

                                                    <button className="ml-3 p-1 inline-flex border border-transparent bg-red-700 hover:bg-red-900 text-white shadow rounded-md" onClick={() => deleteUser(user.id)}>
                                                        <TrashIcon className="h-4 w-4 inline" />
                                                    </button>
                                                </>
                                            : null}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            <nav aria-label="Pagination"
                                 className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing
                                        {' '}
                                        <span className="font-medium">{data.from}</span>
                                        {' '}
                                        to
                                        {' '}
                                        <span className="font-medium">{data.to}</span>
                                        {' '}
                                        of
                                        {' '}
                                        <span className="font-medium">{data.total}</span>
                                        {' '}
                                        results
                                    </p>
                                </div>
                                <div className="flex-1 flex justify-between sm:justify-end">
                                    <Button onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</Button>
                                    <Button onClick={() => setPage(page + 1)} disabled={data.current_page === data.last_page} className="ml-3">Next</Button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </View>
    )
}

export default UserList;