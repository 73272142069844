import React, {memo} from 'react';
import {countries} from 'country-data-list';

// Helpers ----

const allCountries = countries
  .all
  .filter(country => !!country.alpha3)

// Component ----

function CountryOptions() {
  return (
    <>
      {allCountries.map(country => (
        <option value={country.name} key={`${country.alpha3}-${country.alpha2}`}>
          {country.name}
        </option>
      ))}
    </>
  );
}

export default memo(CountryOptions);