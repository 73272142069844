import React, {ChangeEvent} from 'react';
import CurrencyOptions from "./CurrencyOptions";

// Types ----

type CurrencySelectProps = {
  id?: string
  value?: string | null
  onChange: (currency: string) => void
  required?: boolean
}

// Component ----

function CurrencySelect({id, value, onChange, required}: CurrencySelectProps) {

  function selectCurrency(event: ChangeEvent<HTMLSelectElement>) {
    onChange(event.target.value)
  }

  return (
    <select
      id={id}
      value={value || 'GBP'}
      onChange={selectCurrency}
      className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
      required={!!required}
    >
      <CurrencyOptions/>
    </select>
  );
}

export default CurrencySelect;