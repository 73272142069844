import * as React from "react";
import {concatenate} from "../../services/string";

enum Size {X_SMALL, SMALL, MEDIUM, LARGE, X_LARGE}

enum Color {LIGHT, DARK}

type Props = {
    size: Size
    color: Color
};

const sizeClasses: Record<Size, string> = {
    [Size.X_SMALL]: 'h-4 w-4',
    [Size.SMALL]: 'h-6 w-6',
    [Size.MEDIUM]: 'h-8 w-8',
    [Size.LARGE]: 'h-12 w-12',
    [Size.X_LARGE]: 'h-14 w-14',
}

const colorClasses: Record<Color, string> = {
    [Color.LIGHT]: 'text-white',
    [Color.DARK]: 'text-indigo-800',
}

function Spinner(props: Props) {
    const {size, color} = props

    return (
        <svg className={concatenate("animate-spin", sizeClasses[size], colorClasses[color])}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
        </svg>
    );
}

Spinner.sizes = Size
Spinner.colors = Color

Spinner.defaultProps = {
    size: Size.MEDIUM,
    color: Color.LIGHT
}

export default Spinner;