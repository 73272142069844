import React, {memo} from 'react';
import locales from 'locale-codes';

// Helpers ----

const allLocales = locales.all.filter(locale => !locale.name.startsWith('Pseudo'));

// Component ----

function LocaleOptions() {
  return (
    <>
      {allLocales.map(locale => (
        <option value={locale.tag} key={locale.tag}>
          {locale.name} {!!locale.location && ` - ${locale.location}`}
        </option>
      ))}
    </>
  );
}

export default memo(LocaleOptions);