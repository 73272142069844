import client from "./client";

type AuthResult = {
  loggedIn: boolean
  message?: string
}

type CurrentUser = {
  id: number
  name: string
  profile_photo_url: string
}

type User = {
  id?: number
  name: string
  profile_photo_url: string
  password?: string
}

type UserCredentials = {
  email: string
  password: string
}

type Operator = {
  id: number
  name: string
  domain: string
  is_active: boolean
  settings: OperatorSettings
}

type ProvisionedOperator = Operator & {
  admin_user: UserCredentials
}

type OperatorSettings = {
  general: OperatorGeneralSettings
  social: OperatorSocialSettings
  legal: OperatorLegals
  plan: OperatorPlan
  branding: OperatorBranding
  glossary: OperatorGlossary
  audience_app: OperatorAudienceAppSettings
}

type OperatorGeneralSettings = {
  name: string
  subdomain: string
  email: string
  phone: string | null
  address: Address
}

type OperatorSocialSettings = {
  facebook: string | null
  twitter: string | null
  instagram: string | null
  tiktok: string | null
}

type OperatorLegals = {
  privacy_policy: LegalDocument | null
  terms_and_conditions: LegalDocument | null
}

type LegalDocument = {
  type: "url" | "text"
  value: string | null
}

type OperatorPlan = "demo" | "pilot"

type OperatorBranding = {
  font: string,
  colour_1: string
  colour_2: string
  colour_3: string
  colour_4: string
  logo: MediaFile | null,
}

type OperatorGlossary = {
  event?: string
  events?: string
  event_type?: string
  event_types?: string
  exhibit?: string
  exhibits?: string
  exhibit_type?: string
  exhibit_types?: string
  facility?: string
  facilities?: string
  fact?: string
  facts?: string
  zone?: string
  zones?: string
}

type OperatorAudienceAppSettings = {
  features: AudienceAppFeatures
}

type AudienceAppFeatures = {
  plan: AudienceAppFeature
  explore: AudienceAppFeature
  discover: AudienceAppFeature
  redeem: AudienceAppFeature
  relive: AudienceAppFeature
}

type AudienceAppFeature = {
  name: string
  order: number
}

type MediaFile = {
  id: number
  name: string
  file_name: string
  size: number
  mime_type: string
  type?: string
  original_url: string
  preview_url?: string
}

type Address = {
  line1: string
  line2?: string
  line3?: string
  city?: string
  postcode: string
  country: string
}

type Localisation = {
  currency?: string
  locale?: string
  timezone?: string
}

type StatChangeData = {
  operator_id: number | string
  date: string
  android_impressions?: number
  android_downloads?: number
  android_conversion_rate?: number
  ios_impressions?: number
  ios_downloads?: number
  ios_conversion_rate?: number
}

const api = {
  auth: {
    csrf: async function (): Promise<boolean> {
      return client.get('/auth/csrf-cookie').then(res => res.status === 200);
    },
    login: async function (email: string, password: string, remember: boolean): Promise<AuthResult> {
      await this.csrf()

      return client.post('/auth/login', {email, password, remember})
        .then(res => ({loggedIn: res.status === 200}))
        .catch(err => ({
          loggedIn: false,
          message: err.response.data.errors?.email?.[0] || err.response.data.message || err.message
        }))
    },
    currentUser: async function (): Promise<CurrentUser | null> {
      return client.get('/user').then(res => res.status === 200 ? res.data : null).catch(() => null)
    },
    logout: async function (): Promise<boolean> {
      return client.post('/auth/logout').then(res => res.status === 204).catch(() => false)
    }
  },

  users: {
    list: async function (): Promise<Array<User>> {
      return client.get('/users').then(res => res.data?.data)
    },
    exists: async function (email: string): Promise<boolean> {
      return client.head(`/users/${email}`).then(res => res.status === 200)
    },
    get: async function (id: number): Promise<User | null> {
      return client.get(`/users/${id}`).then(res => res.data)
    },
    create: async function (data: {
      name: string,
      email: string,
      password: string,
      password_confirmation: string
    }): Promise<User> {
      return client.post('/users', data).then(res => res.data)
    },
    update: async function (data: {
      id: number | string,
      name: string,
      email: string,
      password?: string,
      password_confirmation?: string
    }): Promise<User> {
      return client.put(`/users/${data.id}`, data).then(res => res.data)
    },
    delete: async function (id: number): Promise<boolean> {
      return client.delete(`/users/${id}`).then(res => res.status === 204)
    }
  },

  operators: {
    list: async function (): Promise<Array<Operator>> {
      return client.get('/operators').then(res => res.data?.data)
    },
    get: async function (id: number): Promise<Operator | null> {
      return client.get(`/operators/${id}`).then(res => res.data)
    },
    create: async function (data: {
      name: string,
      domain: string,
      email: string,
      phone?: string,
      address: Address,
      localisation: Localisation
    }): Promise<ProvisionedOperator> {
      return client.post('/operators', data).then(res => res.data)
    },
    update: async function (data: {
      id: number | string,
      name?: string,
      domain?: string,
      email?: string,
      phone?: string | null,
      address?: Address
    }): Promise<Operator> {
      return client.put(`/operators/${data.id}`, data).then(res => res.data)
    },
    delete: async function (id: number): Promise<boolean> {
      return client.delete(`/operators/${id}`).then(res => res.status === 204)
    }
  },

  statChanges: {
    create: async function (data: StatChangeData): Promise<boolean> {
      return client.post(`/stat-changes`, data).then(res => res.status === 204)
    }
  }
};

export default api;