import useSWR from "swr";
import View from "../../../components/View/View";
import {useState} from "react";
import Badge from "../../../components/Badge/Badge";
import Button from "../../../components/Button/Button";
import EmptyState from "../../../components/EmptyState/EmptyState";
import {MapIcon, PencilIcon} from "@heroicons/react/outline";
import {EyeIcon, PlusIcon, TrashIcon} from "@heroicons/react/solid";
import {useNotification} from "../../../context/NotificationContext";
import {Link, useNavigate} from "react-router-dom";
import api from "../../../services/api";
import ConfirmModal from "../../../components/ConfirmModal";

interface Operator {
  id: number
  name: string
  domain: string
  settings: {
    plan: string
  }
}

function OperatorList() {
  const [page, setPage] = useState<number>(1);
  const {data, mutate, error} = useSWR(`/operators?page=${page}`)

  const [deletingOperator, setDeletingOperator] = useState<Operator | null>(null);

  const {notify} = useNotification()
  const navigate = useNavigate()

  // Pre-fetch the next page straight away
  useSWR(`/operators?page=${page + 1}`)

  // Prepare action handlers
  async function handleDeleteOperator(operator: Operator) {
    try {
      await api.operators.delete(operator.id)
      notify("success", "Operator Removed", `${operator.name}'s system has been permanently removed`)

      return mutate()
    } catch (error) {
      notify("error", "Operator Not Removed", `${operator.name}'s system could not be permanently removed`)
    } finally {
      setDeletingOperator(null)
    }
  }

  const title = "Operators"
  const actions = (<>
    <Button color="indigo" size="xs" onClick={() => navigate('/operators/create')}>
      <PlusIcon className="h-5 w-5 mr-2"/>
      New Operator
    </Button>
  </>)

  // Initial state
  if (!data && !error) {
    return (
      <View title={title} actions={actions}/>
    )
  }

  // Empty state
  if (data && data.total === 0) {
    return (
      <View title={title} badge={data.total} actions={actions}>
        <EmptyState
          icon={MapIcon}
          title="No operators"
          subtitle="Get started by provisioning a new operator."
          buttonText="New operator"
          onClick={() => navigate('/operators/create')}
        />
      </View>
    )
  }

  // Data state
  return (
    <View title={title} badge={data.total} actions={actions}>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                  >
                    Domain
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                  >
                    Plan
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Options</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                {data.data.map((operator: Operator, index: number) => (
                  <tr key={operator.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-indigo-900">
                      <Link to={`/operators/${operator.id}`}>
                        {operator.name}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                      {operator.domain}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                      {operator.settings.plan}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Badge color="green">Active</Badge>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-light">
                      <Link
                        to={`/operators/${operator.id}`}
                        className="p-1 inline-flex border border-transparent bg-indigo-700 hover:bg-indigo-900 shadow rounded-md text-white">
                        <EyeIcon className="h-4 w-4"/>
                      </Link>

                      <Link
                        to={`/operators/${operator.id}/update`}
                        className="ml-3 p-1 inline-flex border border-transparent bg-indigo-700 hover:bg-indigo-900 shadow rounded-md text-white">
                        <PencilIcon className="h-4 w-4"/>
                      </Link>

                      <button
                        className="ml-3 p-1 inline-flex border border-transparent bg-red-700 hover:bg-red-900 text-white shadow rounded-md"
                        onClick={() => setDeletingOperator(operator)}>
                        <TrashIcon className="h-4 w-4 inline"/>
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>

              {/* Pagination */}
              <nav aria-label="Pagination"
                   className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing
                    {' '}
                    <span className="font-medium">{data.meta.from}</span>
                    {' '}
                    to
                    {' '}
                    <span className="font-medium">{data.meta.to}</span>
                    {' '}
                    of
                    {' '}
                    <span className="font-medium">{data.meta.total}</span>
                    {' '}
                    results
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  <Button onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</Button>
                  <Button onClick={() => setPage(page + 1)} disabled={data.meta.current_page === data.meta.last_page}
                          className="ml-3">Next</Button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <ConfirmModal
        show={!!deletingOperator}
        type={ConfirmModal.types.DANGER}
        title="Remove Operator"
        body={`Are you sure you want to permanently remove '${deletingOperator?.name}'? This action cannot be undone.`}
        value={deletingOperator?.name}
        approveLabel="Delete"
        onApprove={() => deletingOperator ? handleDeleteOperator(deletingOperator) : null}
        onCancel={() => setDeletingOperator(null)}
      />
    </View>
  )
}

export default OperatorList;