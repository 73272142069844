import {SWRConfig} from "swr";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import client from "../../services/client";
import {AxiosError} from "axios";

function Config({children}: { children: React.ReactNode }) {
    const navigate = useNavigate()
    const auth = useAuth()

    // Fetch data from the given URL
    const fetcher = (url: string) => client.get(url).then(res => res.data);

    // Handle errors thrown by the fetcher
    const errorHandler = (error: AxiosError) => {
        const status = error.response?.status || 500

        // Notify Sentry and show an error message on-screen
        if (![401, 403, 404].includes(status)) {
            //
        }

        // The current session has expired,
        if (status === 401) {
            auth.logout().then(() => {
                navigate('/')
            })
        }
    }

    return (
        <SWRConfig value={{fetcher, onError: errorHandler}}>
            {children}
        </SWRConfig>
    );
}

export default Config;