import React, {memo} from 'react';
import {currencies} from 'country-data-list';

// Helpers ----

const excludedCodes = ['USS', 'USN', 'XAG', 'XAU', 'XPD', 'XPT', 'XBA', 'XBB', 'XBC', 'XBD', 'XTS', 'XXX'];

const allCurrencies = currencies
  .all
  .filter(currency => !currency.name.includes('funds code')
    && !currency.name.includes('complementary currency')
    && !currency.name.includes('Lithuanian litas')
    && !excludedCodes.includes(currency.code)
  );

// Component ----

function CurrencyOptions() {
  return (
    <>
      {allCurrencies.map(currency => (
        <option value={currency.code} key={currency.code}>
          {currency.code} - {currency.name}
        </option>
      ))}
    </>
  );
}

export default memo(CurrencyOptions);