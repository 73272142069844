import React, {useEffect} from 'react';
import {FieldAttributes, useField, useFormikContext} from "formik";

// Types ----

type SubdomainFieldProps = FieldAttributes<any> & {
  suggestionField?: string
}

// Helpers ----

function suggestSubdomain(text: string): string {
  return text
    .trim()
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
}

// Component ----

function SubdomainField({suggestionField, ...props}: SubdomainFieldProps) {
  const {values, setFieldValue} = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(props);

  useEffect(() => {

    if(!!suggestionField && !meta.touched) void setFieldValue(props.name, suggestSubdomain(values[suggestionField]));

  }, [values, suggestionField, setFieldValue, props.name, meta.touched]);

  return (
    <>
      <input {...props} {...field} />
      {meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
}

export default SubdomainField;