import {DateTime} from "luxon";
import * as React from "react";

interface Props {
    target: string
    preContent: string | null | undefined
    postContent: string | null | undefined
    dateTime: DateTime
    colour: string
    icon: (props: React.ComponentProps<'svg'>) => JSX.Element
    isLast: boolean
}

function TimelineEntry(props: Props) {
    return (
        <li>
            <div className="relative pb-8">
                {!props.isLast ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>
                ) : null}
                <div className="relative flex space-x-3">
                    <div>
                  <span
                      className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${props.colour}`}>
                    <props.icon className="h-5 w-5 text-white" aria-hidden="true"/>
                  </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                            <p className="text-sm text-gray-500">
                                {props.preContent}{' '}
                                <span className="font-medium text-gray-900">{props.target}</span>
                                {' '}{props.postContent}
                            </p>
                        </div>
                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                            <time dateTime={props.dateTime.toISO()}>{props.dateTime.toFormat('t DD')}</time>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default TimelineEntry;